.button {
    display: block;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1rem 3.2rem;
    outline: none;
    cursor: pointer;
    transition: transform 0.2s ease-out;
    border: none;
    box-shadow: 10px 8px 30px #0C213C;
    border-radius: 8px;

    &__blue {
        background: var(--colorButtonBlue);
        color: var(--colorWhite);
    }

    &:hover {
        @media(--md-screen) {
            transform: translateY(-.3rem);
        }
    }
}