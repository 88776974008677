.feature {

    &__inner {
        position: relative;
        background: var(--colorGrey);
        @media(--md-screen) {
            padding-bottom: 2rem;
        }
      
        @media(--lg-screen) {
      
        }
    }

    &__content {
        @media(--xlg-screen) {
            max-width: 197rem;
            display: block;
            margin: 0 auto;
        }
    }

    &__heading {
        font-size: 3.6rem;
        font-weight: 900;
        color: var(--colorBlack);

        &.absolute {
            max-width: 200rem;
            z-index: 9;
            position: relative;
            margin: 0 auto;
            padding: 0 1.7rem;
            text-align: center;
            top: 2.5rem;
     
        @media(--md-screen) {
            position: absolute;
            top: unset;
            text-align: start;
        }

        @media(--xlg-screen) {
            padding: 0 4%;
        }
    }
    @media(--md-screen) {
    font-size: 7.7rem;
    line-height: 8.6rem;
    }
        
        
    }

    &__block {
        position: relative;
        padding: 9rem 0 5rem 0;
        @media(min-width: 537px) and (max-width:1025px) {
            padding: 9rem 0 10rem;
        }
        @media(--md-screen) {
            margin-bottom: 19rem;
            padding: 0rem;
            z-index: 9;
        }
        @media(--xlg-screen) {
            padding: 0 5rem;
        }

/*         &_deep {

            @media(--md-screen) {
                margin-bottom: 30rem;
            }
        } */
    }

    &__text {
        @media(--md-screen) {
            margin-left: 4.5rem;
        }
    }

    &__light {

        &_bottom {
            position: absolute;
            display: block;
            left: 0;
            bottom: -4.5rem;
            width: 100%;
            z-index: 20;
            @media(min-width: 537px) and (max-width:1025px) {
                bottom: -9.5rem;
            }
            @media(--md-screen) {
                display: none;    
            }
        }

        &_top {
            position: absolute;
            display: block;
            left: 0;
            bottom: -2.5rem;
            width: 100%;
            z-index: 20;
            @media(--md-screen) {
                display: none;    
            }
        }

        &_mainbottom {
            position: absolute;
            display: block;
            left: 0;
            bottom: -8rem;
            width: 100%;
            z-index: 20;
            @media(min-width: 537px) and (max-width:1025px) {
                bottom: -12.5rem;
            }
            @media(--md-screen) {
                display: none;    
            }
        }
    }

    &__background {

        @media(--md-screen) {
           
        }

        &_left {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-18%,-23%);
                z-index: 1;
            }
        }

        &_top {
            position: absolute;
            max-width: 100vw;
            width: 100%;
            display: block;
            top: -11.5rem;
            left: 0;
            @media(min-width: 537px) and (max-width:1025px) {
                top: -4.7%;
            }
            @media(--md-screen) {
                top: 0;
                left: 50%;
                transform: translate(-50%, -75%);
                z-index: 0;
            }
        }

        &_laptop-right {
            position: absolute;
            top: .5%;
            left: 50%;
            transform: translate(-50%, 0) scale(0.3);
            z-index: 1;
            @media(min-width: 537px) and (max-width:1025px) {
                transform: translate(-50%, -1rem) scale(.35);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-10%, 22%) scale(0.8);
            }

            @media(--xlg-screen) {
                top: 0;
                left: 50%;
                transform: translate(-10%, 14%);
            }
        }

        &_flower-right {
            position: absolute;
            top: -25.8rem;
            left: 50%;
            transform: translate(-85%, 158%) scale(0.4);
            z-index: 0;
            @media(min-width: 537px) and (max-width:1025px) {
                transform: translate(-105%,160%) scale(.4);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-20%, 143%);
            }

            @media(--xlg-screen) {
                
            }
        }

        &_laptop-left {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, 0) scale(0.35);
            z-index: 10;
            @media(min-width: 537px) and (max-width:1025px) {
                transform: translate(-50%, -4rem) scale(.35);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-110%, 120%) scale(0.8);
                z-index: 1;
            }

            @media(--xlg-screen) {
                transform: translate(-119%, 131%);
            }
            
        }

        &_flower-left {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-110%, 75%);
                z-index: 2;
            }
        }

        &_dots-left {
            position: absolute;
            top: -5%;
            left: 50%;
            transform: translate(-45%, -22%) scale(0.4) rotate(180deg);
            z-index: 0;

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-108%, 17%);
                z-index: 0;
            }
        }

        &_scheme {
            position: absolute;
            top: 52.5%;
            left: 50%;
            transform: translate(-52%, 0) scale(.3);
            z-index: 2;
            @media(min-width: 537px) and (max-width:1025px) {
                transform: translate(-50%, 3rem) scale(.35);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-5%, 250%) scale(0.7);

            }

            @media(--xlg-screen) {
                transform: translate(0%,270%);
            }
        }

        &_scheme2 {

            &.bg {
                display: none;
                @media(min-width: 537px) and (max-width:1025px) {
                    transform: translate(-50%, 0rem) scale(.35);
                }
    
                @media(--md-screen) {
                    display: block;
                    top: 0;
                    left: 50%;
                    transform: translate(135%, 345%) scale(0.8);
    
                }
                @media(--xlg-screen) {
                    transform: translate(200%,370%);
                }
            }
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0) scale(.3);
            z-index: 2;
            @media(min-width: 537px) and (max-width:1025px) {
                transform: translate(-50%, 0rem) scale(.35);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-17%, 425%) scale(0.7);

            }

            @media(--xlg-screen) {
                transform: translate(-0%,465%);
            }
        }

        &_dots-right {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(15%, 106%) rotate(185deg);
                z-index: 0;
            }
        }

        &_laptop {
            position: absolute;
            top: 71%;
            left: 0;
            transform: translate(-35%, 0%) scale(.3);
            z-index: 4;
            @media(min-width: 537px) and (max-width:1025px) {
                top: 72%;
                left: 0;
                transform: translate(-35%) scale(.3);
            }

            @media(--md-screen) {
                display: block;
                top: 0;
                left: 50%;
                transform: translate(-80%, 250%) scale(0.7);
            }
            @media(--lg-screen) {
                transform: translate(-80%,250%) scale(.7);
            } 
            @media(--xlg-screen) {
                transform: translate(-75%,260%) scale(.7);
            }
        }

        &_bottom {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                width: 100%;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 32rem);
                z-index: 1;
            }

            @media(--xlg-screen) {
                transform: translate(-50%, 42rem);
            }
        }

        &_right {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                bottom: 0;
                left: 50%;
                transform: translate(-130%,-20rem);
                z-index: 1;
            }
        }

        &_group {
            position: absolute;
            display: block;
            bottom: 0;
            left: 50%;
            transform: translate(-192%, 10rem);
            z-index: 5;
            @media(--md-screen) {
                display: block;
                bottom: 0;
                left: 50%;
                transform: translate(-190%,5rem);
            }
        }

        &_flowers-right {
            display: none;
            position: absolute;
            @media(--md-screen) {
                bottom: 0;
                left: 50%;
                transform: translate(85%, 40rem);
                z-index: 3;
            }
        }
    }

    &__star {

        &_top {
            position: absolute;
            top: 28%;
            left: 50%;
            transform: translate(-7rem, 0);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(53rem, 65rem);
            }
        }

        &_left {
            position: absolute;
            top: 29%;
            left: 50%;
            transform: translate(12rem, 0);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(-22rem,163rem);
            }
        }

        &_right {
            position: absolute;
            top: 31%;
            left: 50%;
            transform: translate(-15rem, 0);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(75rem, 155rem);
            }
        }

        &_md-top {
            position: absolute;
            display: none;
            @media(--md-screen) {
                display: block;
                top: 14rem;
                left: 50%;
                transform: translate(35rem, 80rem);
            }
        }

        &_lg-bottom {
            position: absolute;
            display: none;
            z-index: 2;
            @media(--md-screen) {
                display: block;
                top: 14rem;
                left: 50%;
                transform: translate(60rem, 190rem);
            }
        }

        &_bottom-left {
            position: absolute;
            top: 45.5%;
            left: 50%;
            transform: translate(15rem);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(-90rem,300rem);
                z-index: 3;
            }
        }

        &_bottom-right {
            position: absolute;
            top: 45.5%;
            left: 50%;
            transform: translate(15rem);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(-50rem,310rem);
                z-index: 3;
            }
        }

        &_md-bottom-right {
            position: absolute;
            top: 51.7%;
            left: 50%;
            transform: translate(-13rem);
            z-index: 3;
            @media(--md-screen) {
                top: 14rem;
                left: 50%;
                transform: translate(-85rem,295rem);
                z-index: 3;
            }
        }
    }

    &__ellipse {

        &_top-right {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-20rem) scale(.5);
            opacity: .7;
            z-index: 3;

            @media(--md-screen) {
                display: block;
                top: 14rem;
                left: 50%;
                transform: translate(39rem, 51rem);
                opacity: 1;
            }
        }

        &_bottom-right {
            position: absolute;
            top: 47%;
            left: 50%;
            transform: translate(2rem) scale(.5);
            opacity: .7;
            z-index: 3;
            @media(--md-screen) {
                display: block;
                top: 14rem;
                left: 50%;
                transform: translate(50rem, 178rem);
                opacity: 1;
            }
        }

        
    }
}

.benefit {
    background-color: #0e2643;
    &__inner {
        padding: 5rem 0;
    }

    &__heading {
        font-size: 3.6rem;
        line-height: 4.6rem;
        @media (--md-screen) {
        color: var(--colorWhite);
        font-size: 7.2rem;
        line-height: 8.6rem;
        font-weight: 900;
        }
    }
    .container {
        @media (--md-screen) {
        gap: 7.5rem;
        }
    }

    .section__text {
        text-align: center;
        @media (--md-screen) {
           text-align: start;
            }
    }

    .section__head_line:before {
        width: 1.5rem;
    }
}

.benefits {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (--md-screen) {
        row-gap: 9rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: unset;

        @media (--md-screen) {
            gap: 2.2rem;
            width: 26%;
            align-items: flex-start;
        }
    }
}

.benefit-block {
    margin-bottom: 0;

}

.benefit-heading {
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 3.7rem;
}

.section__number {
    display: none;
    @media(--md-screen) {
        display: inherit;
        opacity: 0;
    }

}

.many {
    padding: 2rem 1.7rem 10rem 1.7rem;
    max-width: 100%; 
    @media(--md-screen) {
        padding: 2rem 1.7rem 20rem 1.7rem;
        max-width: inherit; 
    }

    @media(--xlg-screen) {
        padding: 0 5rem 35rem 5rem;
        max-width: 180rem;
    }
}